import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const FriendbookPage = () => (
  <Layout>
    <Seo title="Vennebog" />


    <div className="w-full bg-background h-max p-5 shadow-md">
      <div className="bg-white rounded-lg p-2">

Hej
</div></div>


    <div className="w-full bg-background h-max p-5 shadow-md">
      <div className="bg-white rounded-lg">
        <div className="bg-black text-white p-2 rounded-t-lg">Hej! 👋🏽</div>


        <tr class="border-dashed border-b hover:bg-gray-50">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium  whitespace-nowrap"
                    >
                      Online status
                    </th>
                    <td class="px-6 py-4">
                      <span className="text-green-900">Online</span>
                    </td>
                  </tr>

                  <tr class="border-dashed border-b hover:bg-gray-50">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium  whitespace-nowrap"
                    >
                      Seneste tweet
                    </th>
                    <td class="px-6 py-4">
                      Jeg elsker heste, din mor og sharwarma
                    </td>
                  </tr>

                  <tr class="border-dashed border-b hover:bg-gray-50">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium  whitespace-nowrap"
                    >
                      Navn
                    </th>
                    <td class="px-6 py-4">Mauran Muthiah</td>
                  </tr>

                  <tr class="border-dashed border-b hover:bg-gray-50">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium  whitespace-nowrap"
                    >
                      Sted
                    </th>
                    <td class="px-6 py-4">Tranbjerg, Aarhus</td>
                  </tr>



        </div>
        </div>


  </Layout>
)

export default FriendbookPage
